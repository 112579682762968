body {
    font-family: Honeywell Sans Web, sans-serif;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.centered{
    text-align: center;
}

.normal-text{
    font-size: 1.3rem;
}